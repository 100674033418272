footer{
    background-color: #141414;
}

.social-media{
    display: flex;
    gap: 2rem;
    place-self: start;
}

.social-media a{
    height: 3rem;
    width: 3rem;
    border: 2px solid #D3D3D4;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media svg{
    font-size: 1.5rem;
    color: #D3D3D4;
}