.result-number{
    color: #FFFFFF;
}

div.css-2fcmic-control, div.css-1tj7l1q-control{
    cursor: pointer;
}

.advanced-form-select svg, #collapse-filters svg{
    height: 23px;
    width: auto;
}

.advanced-form-select svg, #collapse-filters svg, div.css-1dimb5e-singleValue, div.css-166bipr-Input, div.css-qbdosj-Input{
    color: #FFFFFF;
    caret-color: #D3D3D4;
}

div.css-1jqq78o-placeholder{
    color: #D3D3D4;
}

div.css-1g7d8qb-control, div.css-1g7d8qb-control:hover{
    outline: none;
    box-shadow: none;
    color: #ffffff;
    background-color: #43474E;
    border: 2px solid #D3D3D4;
}
  
div.css-2fcmic-control:hover, div.css-1yany0l-control, div.css-1yany0l-control:hover{
    outline: none;
    box-shadow: none;
    border: 2px solid #D3D3D4;
}
  
span.css-1u9des2-indicatorSeparator{
    display: none;
}

.css-1nmdiq5-menu{
    background-color: #43474E!important;
    color: #fff;
    overflow: hidden;
}