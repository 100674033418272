/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

/* Global */
body {
  font-family: 'Montserrat', sans-serif!important;
  background-color: #22262F!important;
}

/* Typography */
body h1{
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 134%;
  color: #fff;
  margin: 0;
}

body h2{
  font-weight: 700;
  font-size: 2rem;
  line-height: 134%;
  color: #fff;
  margin: 0;
}

body h3{
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 179%;
  color: #fff;
  margin: 0;
}

body h4{
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 156%;
  color: #fff;
  margin: 0;
}

body label, body .form-label{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 156%;
  color: #FFFFFF;
}

body label.form-label{
  font-weight: 700;
  margin: 0;
}

body p, body input, body select{
  font-weight: 500;
  font-size: 1rem; /* 1.125rem; */
  line-height: 163%;
  color: #fff;
  text-decoration: none;
  margin: 0;
}

body button, body a, a.nav-link, body a:hover, a.nav-link:hover{
  font-weight: 700;
  font-size: 1rem;
  line-height: 163%;
  color: #fff;
  cursor: pointer;
}

body span{
  font-weight: 500;
  font-size: 16px;
  line-height: 163%;
  color: #fff;
}

.preamble{
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 163%;
  color: #FFFFFF;
}

/* Assets */
section{
  padding: 80px 0;
}

body select, select.form-select{
  cursor: pointer;
}

body input, input.form-control, body select, select.form-select{
  padding: 8px 24px;
  margin: 0;
  border: 2px solid #D3D3D4;
  border-radius: 1000px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: #43474E;
  max-height: 42px;
}

input:focus-visible, input.form-control:focus-visible, select:focus-visible, select.form-select:focus{
  outline: none;
  box-shadow: none;
  color: #ffffff;
  background-color: #43474E;
  border: 2px solid #D3D3D4;
}

input.form-control::placeholder{
  color: #D3D3D4;
}

select.form-select{
  background-image: url(assets/dropdown-icon.svg);
}

select.form-select{
  background-size: 16px auto;
  max-height: 42px;
  padding: 8px 24px 8px 24px;
}

.btn-tsl{
  padding: 8px 40px;
  background: #83BB25;
  color: #22262F;
  border: none;
  border-radius: 1000px;
  min-height: 40px;
  transition: 300ms background-color;
}

.btn-tsl:hover{
  background: #76A821;
}

.link-tsl{
  text-decoration: none;
  color: #83BB25;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 300ms background-color;
}


.link-tsl:hover{
  color: #76A821;
}

/* Media queries */
@media (max-width: 991.98px) {
	/* Typography mobile */
	body h1{
		font-size: 1.75rem;
	}
	
	body h2{
		font-size: 1.25rem;
	}

  body h3{
    font-size: 1.125rem;
  }
}

@media (max-width: 767.98px) {
  /* Assets */
  .btn-tsl{
    padding: 8px 24px;
  }
}