.course-card-style{
    background-color: #43474E;
    color: #fff;
    padding: 32px;
    border-radius: 4px;
}

.status-on{
    background: #83BB25;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 1000px;
}

.info-div{
    margin-bottom: 2rem;
}

.info-div .fa-check{
    font-size: 1.125rem;
    color: #83BB25;
}

/* Media queries */
@media (max-width: 991.98px) {
    #collapse-info{
        margin-top: 2rem;
    }
}