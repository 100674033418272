nav{
    background-color: #22262F;
}

a.nav-link{
    padding: 0!important;
}

nav select.form-select{
    padding: 8px 80px 8px 24px;
    width: auto;
}

.navbar-brand img{
    height: 40px;
    width: auto;
}

.google-translate p{
    font-size: 1rem;
    font-weight: 700;
}

.google-translate .form-select{
    font-size: 1rem;
}

button.navbar-toggler{
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button.navbar-toggler:focus{
    box-shadow: none;
}

button.navbar-toggler:focus-visible{
    outline: none;
}

.navbar-toggler svg{
    color: #fff;
    font-size: 1.5rem;
}